import React, { useState } from "react";
import PropTypes from "prop-types";
import "../styles/Subscribe.css";
import jsonp from 'jsonp';


const Subscribe = ({
  placeholder,
  buttonText
}) => {
  const [state, setState] = useState({
    email: ""
  });

  const handleChange = e => {
    setState({ email: e.target.value.trim() });

  };

  const handleSubmit = e => {
    e.preventDefault();
    const url = 'https://greeniotsolutions.us9.list-manage.com/subscribe/post-json?u=6bb6b0e845af7f4deba47d5a6&amp;id=b7e9fef7f3&amp;f_id=009f15e1f0';

    if (state.email) 
    {
      jsonp(`${url}&EMAIL=${state.email}&tags=12959144`, { param: 'c' }, (err, data) => {

        if (err) {
          console.error(err);
          return;
        }
        const { msg, result } = data
        // Log the values to the console
        console.log("Message:", msg);
        console.log("Result:", result);
        // do something with response
        // Construct an object similar to the one your configureNotification function expects
        alert(msg);
      });

      setState({ email: "" }); // Resetting the email state to blank and tags back to default
    
    }
  };

  return (
    <form className="subscribe" onSubmit={handleSubmit}>
      <input
        className="subscribe-email"
        name="email"
        type="email"
        placeholder={placeholder}
        onChange={handleChange}
        value={state.email}
        aria-label="Email Address"
      />
      <button className="subscribe-button" type="submit">
        {buttonText}
      </button>
    </form>
  );
};

Subscribe.propTypes = {
  placeholder: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default Subscribe;