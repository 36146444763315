import React, { Component } from 'react';
import '../styles/CommingSoon.css';
import Typewriter from './Typewriter';
import Countdown from './Countdown';
import Subscribe from "./Subscribe";


class CommingSoon extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showparagraph: false,
      countdown: {
        countdownDate: "2024-01-01 00:00:00"
      },
      subscribe: {
        placeholder: "Enter Email Address",
        buttonText: "Submit"},

      notification: {
          src: "",
          alt: "",
          message: "",
          visible: false,
          level: ""
        }

    };

    this.handleFinishedTyping = this.handleFinishedTyping.bind(this);
  }

  handleFinishedTyping() {
    this.setState({
      showparagraph: true
    });
  }


  render() {
    const texttype = "Emerging from the heart of Edinburgh, Scotland, we're a cutting-edge tech startup with a clear mission. Our focus? Crafting battery-free and cost-effective wireless sensor networks tailored for innovative applications - from intuitive parking solutions to advanced agricultural monitoring. As we delve deeper into R&D, stay tuned for compelling case studies on the horizon.";
    const names = 'Register to our newsletter!';
    

    return (
      <div className="App-Background">
        <header className="App-header">
          <h1>Coming Soon!</h1>
          <Countdown futureDate={this.state.countdown.countdownDate} />
          <p>
            Green IoT Solutions Ltd
          </p>
          <p>
            <Typewriter text={texttype} delay={50} onFinishedTyping={this.handleFinishedTyping} />
          </p>
          {this.state.showparagraph && <p>
            <Typewriter text={names} delay={50} />
          </p>}
          <Subscribe
            placeholder={this.state.subscribe.placeholder}
            buttonText={this.state.subscribe.buttonText}
          />
          

          
        </header>
      </div>
    );
  }
}

export default CommingSoon;