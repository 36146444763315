import React, { useState, useEffect } from 'react';


const Typewriter = ({ text, delay, onFinishedTyping }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    } else {
      if (onFinishedTyping) {
        onFinishedTyping();
      }
    }
  }, [currentIndex, delay, text, onFinishedTyping]);

  return <span>{currentText}</span>;
};

export default Typewriter;